import { Button, Flex, HStack, Link, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { FaPhoneAlt } from "react-icons/fa";
import { contactnumber } from '../../lib/Constants';


const HomeHedButton = () => {
    return (
        <Flex
            background="#FB1923"
            boxShadow="-5px 16px 49px -14px #ffbbbb"
            rounded="50px"
            color='white'
            width="fit-content"
            alignItems="center"
        >
            <Link py={3}
                px={4}
                color="#fff"
                target="_blank"
                href={`tel:+91${contactnumber}`}
            >
                <HStack>
                    <Button
                        width={10}
                        height={10}
                        p={0}
                        rounded="50px"
                        background="transparent"
                        border="solid 1px white"
                        _hover={{ bg: 'transparent' }}
                    > <FaPhoneAlt /> </Button>
                    <VStack spacing={0} px={4} alignItems="baseline">
                        <Text
                            className='figures'
                            fontSize={23}
                            letterSpacing="2px"
                        >{contactnumber}</Text>
                        <Text
                            className='figures'
                            fontSize={7}
                            letterSpacing="3px"
                        ><b>24x7</b> HELPLINE NUMBER</Text>
                    </VStack>
                </HStack>
            </Link>
        </Flex>
    )
}

export default HomeHedButton