import { Img } from '@chakra-ui/image'
import { Box, Link } from '@chakra-ui/layout'
import React from 'react'
import whatsapp from '../../assets//icon/whatsapp.svg'
import { whatsapplink } from '../../lib/Constants'
// import whatsapp from '../../assets/images/whatsapp.svg'

const HomeWhatappIcon = () => {
    return (
        <>
            <Box display={{base:'none',sm:'none',md:'inherit'}} position="fixed" right={0} zIndex={999}>
                <Link target="_blank" href={whatsapplink}>
                    <Img alt='helpnow' loading='lazy' height='80px' opacity="0.8" src={whatsapp} />
                </Link>
            </Box>
        </>
    )
}

export default HomeWhatappIcon