import { Image } from '@chakra-ui/image'
import { Box, Flex, Grid, GridItem, HStack, VStack } from '@chakra-ui/layout'
import React, { useState } from 'react'
import './header.css'
import { Link } from 'react-router-dom';
import LogoRed from "../../assets/image/LogoRed.png";
import HomeHedButton from '../Atoms/HomeHedButton';
import { IconButton } from '@chakra-ui/button';
import { FiMenu } from 'react-icons/fi'
import OpenHeader from './OpenHeader';
import NavMenu from './NavMenu';
const Header2 = () => {
    const [showHeaderPopup, setShowheaderPopup] = useState(false);
    const onClickHandler = () => {
        setShowheaderPopup(!showHeaderPopup)
    }
    return (
        <>
            {showHeaderPopup && <OpenHeader setShowheaderPopup={() => setShowheaderPopup(false)} onClickHandler={onClickHandler} />}
            <Box
            bg='#fff'
            filter='drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.10))'
            position='sticky'
            top={0}
            zIndex={9999}
            >
            <Box
                py={{ base: '4', sm: '4', md: '4', lg: '4', xl: '0' }}
                px={{ base: '4', sm: '4', md: '8', lg: '8', xl: '8' }}
                maxW="90em"
                mx="auto" my={0}
                transition="0.5s ease-out">
                <Grid
                    display={{ base: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'grid' }}
                    alignItems="center" templateColumns='repeat(12, 1fr)' gap={6}>
                    <GridItem colSpan={2}>
                        <VStack alignItems="inherit">
                            <Link to="/">
                                <Image alt='helpnow' loading='lazy' maxW={200} src={LogoRed} />
                            </Link>
                            <HStack className='logo_link' >
                                <Link to="/ambulance-service-in-mumbai">MUMBAI</Link>
                                <Box
                                    background="#000"
                                    width={1}
                                    height={1}
                                    rounded="50px"
                                    my="auto"
                                ></Box>
                                <Link to="/ambulance-service-in-delhi"> DELHI</Link>
                                <Box
                                    background="#000"
                                    width={1}
                                    height={1}
                                    rounded="50px"
                                    my="auto"
                                ></Box>
                                <Link to="/ambulance-service-in-bangalore">BANGALORE</Link>
                            </HStack>
                        </VStack>
                    </GridItem>
                    <GridItem colSpan={7} textAlign="center">
                       <NavMenu />
                    </GridItem>
                    <GridItem colSpan={3} display='flex' justifyContent='end'>
                        {/* <IconButton
                            textAlign="end"
                            className='head_icon'
                            onClick={onClickHandler}
                            variant='unstyled'
                            colorScheme='teal'
                            aria-label='Call Sage'
                            fontSize='30px'
                            icon={<FiMenu />}
                        /> */}
                          <HomeHedButton />
                    </GridItem>
                </Grid>
                <Box display={{ base: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'none' }}>
                    <Flex justifyContent="space-between" alignItems="center">
                        <VStack alignItems="inherit" mb={2}>
                            <Link to="/">
                                <Image alt='helpnow' loading='lazy' maxW={200} src={LogoRed} />
                            </Link>
                            <HStack className='logo_link' >
                                <Link to="/ambulance-service-in-mumbai">MUMBAI</Link>
                                <Box
                                    background="#000"
                                    width={1}
                                    height={1}
                                    rounded="50px"
                                    my="auto"
                                ></Box>
                                <Link to="/ambulance-service-in-Delhi"> DELHI</Link>
                                <Box
                                    background="#000"
                                    width={1}
                                    height={1}
                                    rounded="50px"
                                    my="auto"
                                ></Box>
                                <Link to="/ambulance-service-in-bangalore">BANGALORE</Link>
                            </HStack>
                        </VStack>
                       <Box display={{base:'none',sm:'none',md:'block',lg:'block',xl:'none'}}>
                       <HomeHedButton />
                       </Box>
                        <Box>
                            <IconButton
                                textAlign="end"
                                className='head_icon'
                                onClick={onClickHandler}
                                variant='unstyled'
                                colorScheme='teal'
                                aria-label='Call Sage'
                                fontSize='30px'
                                icon={<FiMenu />}
                            />
                        </Box>
                    </Flex>
                    <Box display={{base:'block',sm:'block',md:'none',lg:'none',xl:'none'}}>
                    <HomeHedButton />
                    </Box>
                </Box>
            </Box>
            </Box>
        </>
    )
}

export default Header2