import React from 'react'
import HomeBody from './HomeBody'

const Home = () => {
    return (
        <>
            <HomeBody />
        </>
    )
}

export default Home