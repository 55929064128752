import { Box } from "@chakra-ui/react";
import React, { useRef } from "react";
// import Article from "../../components/Article";
// import InternationalNews from "../../components/InternationalNews";
// import NationalNews from "../../components/NationalNews";
// import PressKit from "../../components/PressKit";
import ReleaseSlider from "../../components/ReleaseSlider";
const InternationalNews = React.lazy(() => import('../../components/InternationalNews'));
const NationalNews = React.lazy(() => import('../../components/NationalNews'));
const PressKit = React.lazy(() => import('../../components/PressKit'));
// const ReleaseSlider = React.lazy(() => import('../../components/ReleaseSlider'));

const Release = () => {
    const refff = useRef()
    return (
        <>
            <Box mb='22px'>
                <ReleaseSlider ref={refff} />
                {/* <Article /> */}
                {/* <Releasecontent /> */}
                {/* <Featuredarticle ref={refff} /> */}
                <NationalNews />
                <InternationalNews />
                <PressKit />
            </Box>
        </>
    )
}
export default Release
