import { Box, Button, Card, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, Heading, Input, InputGroup, InputLeftAddon, InputLeftElement, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import * as Yup from 'yup';
import { Config } from '../../lib/Config';
import axios from 'axios';
import { useFormik } from 'formik';

const contactformvalidation = Yup.object().shape({
    name: Yup.string().required('Required'),
    phoneNumber: Yup.string().required('Required').min(10, 'Mobile number must be of 10 digits').max(10, 'Mobile number must be of 10 digits'),
})
const CallBackForm = () => {
    const reg = new RegExp('^[0-9]+$');
    const formik = useFormik({
        initialValues: {
            name: '',
            phoneNumber: '',
        },
        validationSchema: contactformvalidation,
        onSubmit: async (values) => {
            console.log("skdjbkjnsknvlk after_submission", values);
            try {
                const res = await axios.post(Config.Contact_Form_Url, values)
                console.log(res, "Kolo");
                if (res.status === 200) {
                    resetForm()
                    document.getElementById('contact-form').reset();
                }
            }
            catch (error) {
                console.log(error, "error");
            }
        }
    })

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        resetForm,
        errors,
        touched
    } = formik;

    console.log(values, "valla");
    console.log("skdjbkjnsknvlk before_submission", values, errors);
    return (
        <Box
            display={{ base: 'none', sm: 'none', md: 'none', lg: 'none', 'xl': 'block' }}
            position='absolute'
            right='0%'
            // bottom='0%'
            bottom='-22%'
        >
            <Card
                maxW='400'
                boxShadow='0px 16px 32px 0px rgba(255, 17, 17, 0.16);'
                rounded='10px'
                bg="#fff"
            >
                <CardHeader
                    roundedTop='10px'
                    bg='#FB1923'
                >
                    <Heading textAlign='center' fontSize='xl' fontWeight={600} letterSpacing='0.36px' color='#fff'>Get a Call back within 30 seconds</Heading>
                </CardHeader>
                <CardBody pb={0} px={8}>
                    <form onSubmit={handleSubmit} id='contact-form' >
                        <Stack spacing={4}>
                            <FormControl isInvalid={errors.name && touched.name} >
                                <Input
                                    value={values.name} onChange={handleChange} id='name' name='name' onBlur={handleBlur}
                                    variant='outline'
                                    placeholder='Enter Name'
                                    borderColor='#A2A2A2'
                                    fontSize={14}
                                    px={6}
                                    color='#000'
                                    size='lg'
                                    type='text'
                                    _placeholder={{ color: 'inherit' }}
                                    _focus={{
                                        boxShadow: 'none',
                                        outline: 'transparent'
                                    }}
                                    _focusVisible={{
                                        borderColor: '#A2A2A2'
                                    }}
                                />
                                <FormErrorMessage>{errors.name}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.phoneNumber && touched.phoneNumber} borderLeft='none'>
                                <InputGroup   size='lg'>
                                    <InputLeftElement pr={2} width='auto' className='leftDivider' left='9%' fontSize={14} pointerEvents='none'>
                                        +91
                                    </InputLeftElement>
                                    <Input
                                    borderColor={(errors.phoneNumber && touched.phoneNumber) ? '#ff5145' : '#A2A2A2'}
                                        fontSize={14}
                                        variant='outline'
                                        size='lg'
                                        paddingLeft={16}
                                        _placeholder={{ color: 'inherit' }}
                                        _focus={{
                                            boxShadow: 'none',
                                            outline: 'transparent'
                                        }}
                                        _focusVisible={{
                                            borderColor: '#A2A2A2'
                                        }}
                                        _hover={{
                                            borderColor: '#A2A2A2'
                                        }}
                                        type='tel' placeholder='Enter Phone number'
                                        value={values.phoneNumber}
                                        onChange={(e) => {
                                            if (reg.test(e.target.value) || !e.target.value) {
                                                formik.handleChange(e)
                                            }
                                        }}
                                        id='phoneNumber' name='phoneNumber' maxLength={10} onBlur={handleBlur}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                        <CardFooter
                            justifyContent='center'
                        >
                            <Button
                                type='submit'
                                fontSize={16}
                                fontWeight={500}
                                px={6}
                                py={3}
                                height='auto'
                                variant='solid'
                                backgroundColor='#FF1111'
                                color='#fff'
                                _hover={{
                                    backgroundColor: '#ff3232'
                                }}
                                letterSpacing='0.16px'
                            >
                                Call for Ambulance
                            </Button>
                        </CardFooter>
                    </form>
                </CardBody>
            </Card>
        </Box >
    )
}

export default CallBackForm