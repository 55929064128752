export const contactnumber = '88222 88222'
export const contactnumberwithcountrycode = '+918822288222'

export const whatsapplink = 'https://wa.me/+918822288222'

export const Address = `101, LD building, 1st floor, Mehra Industrial Estate, LBS Marg, Vikhroli (west), Mumbai - 400079.`

export const blsshort = 'BLS'
export const blsHeadingHero = "BLS- Basic Ambulance With O2 ( oxygen)"
export const blsContentHero = "Offering rapid response and oxygen support for patients in need of basic medical assistance."
export const blsquestion = 'What is BLS ?'
export const blsanswer1 = 'Basic Life Support (BLS) is a fundamental level of medical care provided to individuals in life-threatening situations.'
export const blsanswer2 = 'It involves basic techniques such as CPR, defibrillation, and airway management to stabilize patients until further medical assistance arrives.'

export const Blsheading1 = 'Expertise'
export const Blsheading1Content = 'Our team comprises highly trained professionals adept at Basic Life Support (BLS) techniques.'

export const Blsheading2 = 'Reliability'
export const Blsheading2Content = 'Count on HelpNow for consistent and dependable BLS private ambulance services in critical situations.'

export const Blsheading3 = 'Timeliness'
export const Blsheading3Content = 'Understanding the urgency of emergencies, we ensure swift response times to provide life-saving care via our ambulance phone number'

export const Blsfeature1 = 'CPR'
export const Blsfeature1Content = 'Immediate initiation of chest compressions to maintain blood circulation'
export const Blsfeature2 = 'Providing Oxygen'
export const Blsfeature2Content = 'Rapid administration of oxygen to ensure adequate oxygenation of tissues.'
export const Blsfeature3 = 'Airway Management'
export const Blsfeature3Content = 'Ensuring open airways to facilitate breathing in patients. (as when required)'



export const Alsshort = 'ALS'
export const AlsName = 'Advance Ambulance'
export const AlsHeadingHero = "ALS - Cardiac Ambulance ICU Setup With Doctor"
export const AlsContentHero = "Providing advanced cardiac care and intensive care support with a trained BHMS doctor onboard."
export const Alsquestion = 'What is an Advance Ambulance ?'
export const Alsanswer1 = 'An advanced ambulance is a specialized medical transport vehicle equipped with advanced life support equipment and facilities, including ICU setups, to cater to patients with complex medical needs.'
export const Alsanswer2 = 'It is designed to provide immediate medical care and monitoring during transit, particularly for patients requiring intensive care, such as those with cardiac emergencies.'
export const Alsheading1 = 'Expert Medical Team'
export const Alsheading1Content = 'Our team includes experienced doctors and paramedics trained to handle critical care scenarios during transit.'
export const Alsheading2 = 'Advanced Equipment'
export const Alsheading2Content = 'Our advanced ambulances are equipped with ICU setups and cardiac monitoring systems, ensuring comprehensive medical care on the go.'
export const Alsheading3 = 'Timely Response'
export const Alsheading3Content = 'We prioritize prompt response times, ensuring that our advanced ambulances reach you swiftly in emergencies, ensuring timely access to "cardiac ambulance number".'
export const Alsfeature1 = 'ICU Setup'
export const Alsfeature1Content = 'Our advanced ambulances are equipped with intensive care unit setups, including ventilators, cardiac monitors, and other life-saving equipment.'
export const Alsfeature2 = 'Doctor on Board'
export const Alsfeature2Content = 'Each advanced ambulance is staffed with a qualified doctor, ensuring immediate medical intervention and supervision during transit, meeting the needs of those seeking to "book cardiac ambulance service".'
export const Alsfeature3 = 'Cardiac Care'
export const Alsfeature3Content = 'Specialized cardiac ambulances are equipped to handle emergencies, including defibrillation and advanced cardiac life support procedures.'


export const Nicushort = 'NICU'
export const NicuName = 'Newborns Baby ICU'
export const NicuHeadingHero = "NICU - Newborns Baby ICU"
export const NicuContentHero = "Ensuring specialized intensive care for newborns with dedicated medical attention from a doctor."
export const Nicuquestion = 'What is NICU (Newborn Baby ICU) ?'
export const Nicuanswer1 = 'The Neonatal Intensive Care Unit (NICU) is a specialized medical unit dedicated to the care of critically ill or premature newborn babies.'
export const Nicuanswer2 = 'It provides advanced medical care and monitoring for newborns who require intensive support, including premature infants, or babies with medical conditions requiring specialized treatment.'
export const Nicuheading1 = 'Expert Neonatal Team'
export const Nicuheading1Content = 'Our NICU is staffed by a team of skilled neonatologists, nurses, and other healthcare professionals trained in newborn care.'
export const Nicuheading2 = 'State-of-the-Art Facilities'
export const Nicuheading2Content = 'Our NICU is equipped with advanced medical technology, including incubators, ventilators, and monitoring systems, to ensure optimal care for newborns.'
export const Nicuheading3 = `Family Support`
export const Nicuheading3Content = `We prioritize family-centered care, providing emotional support and guidance to parents throughout their baby's NICU stay`
export const Nicufeature1 = 'Advanced Medical Equipment'
export const Nicufeature1Content = 'Our neonatal ambulance service is equipped with respiratory support, intravenous therapy, and monitoring tailored to the unique needs of newborns.'
export const Nicufeature2 = '24/7 Monitoring'
export const Nicufeature2Content = 'Our team provides round-the-clock monitoring and care for newborns at the best NICU Cost, ensuring timely intervention and support.'
export const Nicufeature3 = 'Multidisciplinary Approach'
export const Nicufeature3Content = 'We adopt a multidisciplinary approach to newborn care, collaborating with other specialists as needed to provide comprehensive medical treatment.'

export const Hearseshort = 'HAS'
export const HearseName = 'Hearse Ambulance Service'
export const HearseHeadingHero = "Hearse Ambulance Service - Dead Body With Ice box"
export const HearseContentHero = "Facilitating dignified transportation of deceased individuals with proper preservation methods."
export const Hearsequestion = 'What is a Hearse Ambulance Service ?'
export const Hearseanswer1 = 'A hearse ambulance service is a specialized transportation service designed for the dignified transfer of deceased individuals from one location to another.'
export const Hearseanswer2 = 'It is equipped with facilities such as an icebox to preserve the body during transit, ensuring proper care and respect for the deceased.'
export const Hearseheading1 = 'Reliable and Timely Service'
export const Hearseheading1Content = 'We understand the importance of timely transportation, hence we provide reliable Mortuary Van Service in difficult times.'
export const Hearseheading2 = 'Respectful Handling'
export const Hearseheading2Content = 'We prioritize the respectful handling of the deceased, ensuring that every aspect of the transportation process is conducted with care and dignity.'
export const Hearseheading3 = `Best Equipment`
export const Hearseheading3Content = `Our state-of-the-art facilities guarantee top-notch Dead Body Ambulance services, equipped with the latest technology and resources.`
export const Hearsefeature1 = 'Icebox Facility'
export const Hearsefeature1Content = 'Our hearse ambulances are equipped with freezer box to preserve the body during transit, maintaining its condition until reaching the destination.'
export const Hearsefeature2 = '24/7 Availability'
export const Hearsefeature2Content = 'We offer round-the-clock availability to accommodate transportation needs at any time of day or night.'
export const Hearsefeature3 = 'Discreet Service'
export const Hearsefeature3Content = 'We understand the sensitive nature of the service, ensuring discreet and respectful handling throughout the transportation process.'

export const Airshort = 'AAS'
export const AirName = 'Air Ambulance Service'
export const AirHeadingHero = "Air Ambulance Service"
export const AirContentHero = "Enabling swift and efficient medical transportation via air for critical cases requiring urgent care."
export const Airquestion = 'What is an Air Ambulance Service ?'
export const Airanswer1 = 'An air ambulance service involves the transportation of patients via aircraft, typically helicopters or fixed-wing planes, to access medical care or facilities that are not readily available by ground transportation.'
export const Airanswer2 = 'It is equipped with medical equipment and staffed by trained medical personnel to provide critical care during transit.'
export const Airheading1 = 'Experienced Team'
export const Airheading1Content = 'Our air ambulance service is staffed by experienced pilots, medical professionals, and support staff trained to handle medical emergencies in the air.'
export const Airheading2 = 'Comprehensive Coverage'
export const Airheading2Content = 'We offer comprehensive medical evacuation services, including inter-hospital transfers, repatriation, and emergency medical evacuations, ensuring a seamless continuum of care.'
export const Airheading3 = `Safety and Reliability`
export const Airheading3Content = `Safety is our top priority, and we adhere to stringent safety protocols to ensure the safe transportation of patients, even in challenging conditions.`
export const Airfeature1 = 'Critical Care Transport'
export const Airfeature1Content = 'Our air ambulances are equipped with advanced life support equipment, including ventilators, cardiac monitors, and medication, to provide intensive care during transit.'
export const Airfeature2 = '24/7 Availability'
export const Airfeature2Content = 'We operate round-the-clock, providing air ambulance services whenever and wherever they are needed.'
export const Airfeature3 = 'Coordination and Logistics'
export const Airfeature3Content = 'Our team manages all aspects of the transportation process, including flight coordination, ground transportation, and medical clearance, to ensure a smooth and efficient transfer of patients.'

export const Trainshort = 'TAS  '
export const TrainName = 'Train Ambulance Service'
export const TrainHeadingHero = "Train Ambulance Service"
export const TrainContentHero = "Providing medical evacuation and transportation via train, ensuring safe transit for patients across distances."
export const Trainquestion = 'What is a Train Ambulance Service ?'
export const Trainanswer1 = 'A train ambulance service involves the transportation of patients via train, equipped with medical facilities and staffed by medical professionals, to transfer patients over long distances.'
export const Trainanswer2 = 'It provides a cost-effective and efficient means of medical transportation, particularly for inter-city or inter-state transfers.'
export const Trainheading1 = 'Experienced Medical Team'
export const Trainheading1Content = 'Our train ambulance service is staffed by experienced medical professionals trained in emergency care and patient transportation.'
export const Trainheading2 = 'Seamless Coordination'
export const Trainheading2Content = 'We handle all aspects of the transportation process, including booking, medical clearance, and ground transportation, ensuring a seamless transfer from start to finish.'
export const Trainheading3 = `Affordable Options`
export const Trainheading3Content = `We offer competitive pricing and flexible payment options, making our train ambulance service affordable to patients from all walks of life.`
export const Trainfeature1 = 'Medical Facilities'
export const Trainfeature1Content = 'Our train ambulances are equipped with basic medical facilities, including stretchers, oxygen supply, and first aid kits, to ensure patient comfort and safety during transit.'
export const Trainfeature2 = 'Accessibility'
export const Trainfeature2Content = 'With access to railway networks, our train ambulance service can reach remote or inaccessible areas where other modes of transportation may be limited.'
export const Trainfeature3 = 'Reliable Transportation'
export const Trainfeature3Content = 'We prioritize punctuality and reliability, ensuring timely departure and arrival of train ambulances to facilitate efficient patient transfers.'



export const Reviews = [
    {
        Name: 'Prasad Gurav',
        Review: 'Best ambulance service and best staff and affordable as well, keep up the good work, stephen raj is the best driver, hats off to the helper too. And the support staff Ashwin on the call was helpful too.',
        stars: 5
    },
    {
        Name: 'Dia S.',
        Review: 'Responsive and timely service. The driver and staff were attentive and the ambulance was well-appointed. All safety measures were followed and the entire process was extremely streamlined. Highly recommended.',
        stars: 5
    },
    {
        Name: 'Mahesh Jaggishetty',
        Review: 'My experience with helpnow was very good. The driver and helper was also really helpful and condition of ambulance of was really good. All the equipments was there. Keep it up helpnow, you guys are doing great job.',
        stars: 5
    },
    {
        Name: 'Jagannath Rao S',
        Review: `I had very good experience with HelpNow and Driver was politely responded and he carried my mother very safe and careful from hospital to home.Thank you so much for your service.🙏`,
        stars: 5
    },
    {
        Name: 'Mangesh Gohad',
        Review: 'Excellent Service and very cordial staff. It was a blessing to get connected to them at the time of need. They were quick in responding and provided all the required information. Continue the noble work you guys are doing! More power to you!',
        stars: 5
    }
]


export const logourl = "https://storage.googleapis.com/helpnow-5920157615-prod-gcp-helpnowmedia-zyhmvznoer-gcs/LogoRed.png" 