import { Box } from '@chakra-ui/layout'
import React, { useEffect } from 'react'
import Hero from '../../components/Hero'
// import HelpGallery from '../../components/gallery'
// import HelpAdvantage from '../../components/helpadvantage'
// import HomeCountCard from '../../components/homecount'
// import HowToWork from '../../components/howtowork'
// import Ourclients from '../../components/ourclient'
// import OurServices from '../../components/ourservices'
// import Questions from '../../components/questions'
// import StaffDesicrip from '../../components/staff'
// import Testimonial from '../../components/testimonials'

const HomeCountCard = React.lazy(() => import('../../components/homecount'));
const HowToWork = React.lazy(() => import('../../components/howtowork'));
const Ourclients = React.lazy(() => import('../../components/ourclient'));
const OurServices = React.lazy(() => import('../../components/ourservices'));
const Questions = React.lazy(() => import('../../components/questions'));
const StaffDesicrip = React.lazy(() => import('../../components/staff'));
const Testimonial = React.lazy(() => import('../../components/testimonials'));
const HelpGallery = React.lazy(() => import('../../components/gallery'));
const HelpAdvantage = React.lazy(() => import('../../components/helpadvantage'));


const Homebody = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <Box as='main' my='22px'>
            <Hero />
            <HomeCountCard />
            <HelpAdvantage />
            <OurServices headertext='Our Services' mt={'4rem'} />
            <Ourclients />
            <Testimonial />
            <HowToWork />
            <StaffDesicrip />
            <Questions />
            <HelpGallery />
        </Box>
    )
}

export default Homebody