import { Img } from '@chakra-ui/image'
import { Box, Flex, HStack, VStack } from '@chakra-ui/layout'
import { Link } from 'react-router-dom'
import LogoWhite from '../../assets/image/LogoWhite.png'
import { RiCloseLine } from "react-icons/ri";

const OpenHeader = ({ setShowheaderPopup }) => {
    return (
        <Box
            transition="0.5s ease-out">
            <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                maxW="100vw"
                zIndex={99999}
                transform="translateX(0px) translateY(0%) translateZ(0px)"
                background="#FB1923"
            >
                <Flex as="header"
                    background="#FB1923"
                    py={10}
                    px={8}
                    color="white"
                    justify="space-between"
                    alignItems="center"
                >
                    <Link onClick={setShowheaderPopup} to="/">
                        <Img loading='lazy' maxW={120} src={LogoWhite} alt='logo' /></Link>
                    <HStack
                        display={{ base: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'flex' }}
                        className='open_header'>
                        <Link onClick={setShowheaderPopup} to="/press">Press Releases</Link>
                        {/* <Link onClick={setShowheaderPopup} to="/organisation">Locations</Link> */}
                        <Link onClick={setShowheaderPopup} to="/organisation">About Us</Link>
                        <Link onClick={setShowheaderPopup} to="/contact">Contact</Link>
                        {/* <Link onClick={setShowheaderPopup} to="/services">Services</Link> */}
                        <Link onClick={setShowheaderPopup} to="/blogs">Blogs</Link>
                        {/* <Link >Location Tracking</Link> */}
                    </HStack>
                    <RiCloseLine fontSize={26} onClick={setShowheaderPopup} cursor="pointer" fill='white' />
                </Flex>
                <VStack
                    mb={8}
                    display={{ base: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'none' }}
                    className='open_header'>
                    <Link onClick={setShowheaderPopup} to="/press">Press Releases</Link>
                    {/* <Link onClick={setShowheaderPopup} to="/organisation">Locations</Link> */}
                    <Link onClick={setShowheaderPopup} to="/organisation">About Us</Link>
                    <Link onClick={setShowheaderPopup} to="/contact">Contact</Link>
                    {/* <Link onClick={setShowheaderPopup} to="/services">Services</Link> */}
                    <Link onClick={setShowheaderPopup} to="/blogs">Blogs</Link>
                    {/* <Link >Location Tracking</Link> */}
                </VStack>
            </Box >
        </Box>
    )
}

export default OpenHeader