import { Img } from '@chakra-ui/image'
import { Box, Flex, Text, VStack, Grid, GridItem, Stack, HStack } from '@chakra-ui/layout'
import React from 'react'
import footerlogo from '../../assets/icon/Logo_Red_cedbc299f1.png'
import { AiOutlineHome, AiOutlineWhatsApp } from "react-icons/ai"
import { HiOutlinePhone } from "react-icons/hi"
import { Address, contactnumber, whatsapplink } from '../../lib/Constants'
import { IconButton } from '@chakra-ui/react'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import { HiOutlineMail } from "react-icons/hi";
import phonecalls from '../../assets/icon/phonecalls.svg'
import { Link } from 'react-router-dom'
import Customlink from './customlink'

const Footer = () => {
    return (
        <>
            <Box
                mb={{ base: '8', sm: '8', md: '0' }}
                bg="#F2F2F2"
                pt={{ base: '2rem', sm: '2rem', md: '3rem', lg: '3rem', xl: '3rem', }}
                pb={{ base: '2rem', sm: '2rem', md: '2rem', lg: '2rem', xl: '2rem', }}
                px={{ base: '0', sm: '0', md: '4rem', lg: '4rem', xl: '4rem', }}
            >
                <Box
                    mx="auto"
                    maxW="90vw"
                    transition="0.5 ease-out"
                >
                    <Box mt="3" mb={10} mx={{ base: '0', sm: '0', md: '2rem', lg: '2rem', xl: '2rem', }}>
                        {/* <Flex justifyContent="space-between"> */}
                        <Grid templateColumns='repeat(12, 1fr)' gap={{ base: '4', sm: '4', md: '10', lg: '10' }}>
                            <GridItem colSpan={{ base: '12', sm: '12', md: '8', lg: '4' }}>
                                <VStack alignItems="flex-start" spacing={2}>
                                    <Link to="/">
                                        <Img loading='lazy' maxW={200} my={1} alt='helpnow' src={footerlogo} />
                                    </Link>
                                    <Text fontSize="md" fontWeight="light">care@getHelpNow.in</Text>
                                    <Text className='facts' color='#454545' fontSize={14} fontWeight={400}>
                                        HelpNow is building India’s most reliable medical emergency service, by bringing down the ambulance arrival time from 50 minutes to 10 minutes, using cutting edge technology and operations’ strategy. We have been awarded the "Best Ambulance Service Award 2021," presented by the Honorable Health Minister of India.
                                    </Text>
                                    <HStack className='logo_link' pt={6}>
                                        <Link fontSize={15} to="/ambulance-service-in-mumbai">MUMBAI</Link>
                                        {/* <Box
                                            background="#000"
                                            width={1}
                                            height={1}
                                            rounded="50px"
                                            my="auto"
                                        ></Box> */}
                                        <Link fontSize={15} to="/ambulance-service-in-delhi">| DELHI</Link>
                                        {/* <Box
                                            background="#000"
                                            width={1}
                                            height={1}
                                            rounded="50px"
                                            my="auto"
                                        ></Box> */}
                                        <Link fontSize={15} to="/ambulance-service-in-bangalore">| BANGALORE</Link>
                                    </HStack>
                                </VStack>
                            </GridItem>
                            <GridItem colSpan={{ base: '12', sm: '12', md: '4', lg: '2' }}>
                                <Stack align={'flex-start'}>
                                    <Text className='Manrope' fontSize={'20'} letterSpacing='3%' fontWeight={700} color='#454545' mb={1}>
                                        Our Services
                                    </Text>
                                    <Customlink tolink={'/Services/BasicAmbulance'} text={'BLS-   Basic Ambulance'} />
                                    <Customlink tolink={'/Services/AdvanceAmbulance'} text={'ALS - Cardiac Ambulance'} />
                                    <Customlink tolink={'/Services/NewbornsBabyICU'} text={'NICU - Newborns Baby ICU'} />
                                    <Customlink tolink={'/Services/HearseAmbulanceServices'} text={'Hearse Ambulance Service'} />
                                    <Customlink tolink={'/Services/AirAmbulanceService'} text={'Air Ambulance Service'} />
                                    <Customlink tolink={'/Services/TrainAmbulanceService'} text={'Train Ambulance Service'} />
                                    {/* <Link href='#' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        BLS-   Basic Ambulance
                                    </Link>
                                    <Link href='#' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        ALS - Cardiac Ambulance
                                    </Link>
                                    <Link href='#' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        NICU - Newborns Baby ICU
                                    </Link>
                                    <Link href='#' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        Hearse Ambulance Service
                                    </Link>
                                    <Link href='#' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        Air Ambulance Service
                                    </Link>
                                    <Link href='#' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        Train Ambulance Service
                                    </Link> */}
                                </Stack>
                            </GridItem>
                            <GridItem colSpan={{ base: '12', sm: '12', md: '4', lg: '2' }}>
                                <Stack align={'flex-start'}>
                                    <Text className='Manrope' fontSize={'20'} letterSpacing='3%' fontWeight={700} color='#454545' mb={1}>
                                        Company
                                    </Text>
                                    <Customlink tolink={'/'} text={'Home'} />
                                    <Customlink tolink={'/organisation'} text={'About us'} />
                                    <Customlink tolink={'/organisation#hospitals'} text={'For Hospitals'} />
                                    <Customlink tolink={'/organisation#Corporates'} text={'For Corporates'} />
                                    <Customlink tolink={'/organisation#housingsociety'} text={'For Housing Societies'} />
                                    <Customlink tolink={'/organisation#events'} text={'For Events'} />
                                    {/* <Link to='/' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        Home
                                    </Link>
                                    <Link to='/organisation' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        About us
                                    </Link>
                                    <Link to='/organisation#hospitals' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        For Hospitals
                                    </Link>
                                    <Link to='/organisation#Corporates' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        For Corporates
                                    </Link>
                                    <Link to='/organisation#housingsociety' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        For Housing Societies
                                    </Link>
                                    <Link to='/organisation#events' style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
                                        For Events
                                    </Link> */}
                                </Stack>
                            </GridItem>
                            <GridItem colSpan={{ base: '12', sm: '12', md: '4', lg: '2' }}>
                                <Stack align={'flex-start'}>
                                    <Text className='Manrope' fontSize={'20'} letterSpacing='3%' fontWeight={700} color='#454545' mb={1}>
                                        Get in Touch
                                    </Text>
                                    <Text className='Manrope' fontSize={14} color='#454545'><Text as='b'>Address:</Text> {Address}</Text>
                                    <HStack>
                                        <Img loading='lazy' alt='helpnow' src={phonecalls} />
                                        <Text style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>+91 {contactnumber}</Text>
                                    </HStack>
                                    <HStack>
                                        <HiOutlineMail color='#454545' />
                                        <Text style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>care@getHelpNow.in</Text>
                                    </HStack>
                                </Stack>
                            </GridItem>
                            <GridItem colSpan={{ base: '12', sm: '12', md: '4', lg: '2' }}>
                                <Stack align={'flex-start'}>
                                    <Text className='Manrope' fontSize={'20'} letterSpacing='3%' fontWeight={700} color='#454545' mb={1}>
                                        Social Media
                                    </Text>
                                    <Stack direction={'row'} spacing={0}>
                                        <IconButton
                                            isRound={true}
                                            variant='solid'
                                            aria-label='Done'
                                            fontSize='24px'
                                            background='transparent'
                                            color='#454545'
                                            _hover={{
                                                background: '#FB1923',
                                                color: '#fff'
                                            }}
                                            icon={<FaFacebook />}
                                        />
                                        <IconButton
                                            isRound={true}
                                            variant='solid'
                                            aria-label='Done'
                                            fontSize='24px'
                                            background='transparent'
                                            color='#454545'
                                            _hover={{
                                                background: '#FB1923',
                                                color: '#fff'
                                            }}
                                            icon={<FaInstagram />}
                                        />
                                        <IconButton
                                            isRound={true}
                                            variant='solid'
                                            aria-label='Done'
                                            fontSize='24px'
                                            background='transparent'
                                            color='#454545'
                                            _hover={{
                                                background: '#FB1923',
                                                color: '#fff'
                                            }}
                                            icon={<FaTwitter />}
                                        />
                                    </Stack>
                                    <Link to="/terms" style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545', marginLeft: '10px' }} className='facts'> Terms & Policies  </Link>
                                </Stack>
                            </GridItem>
                            {/* <GridItem colSpan={2}>
                                <VStack className='text_deco' fontWeight={600} fontSize={'24px'} lineHeight={'33px'} >
                                    <Link fontWeight="900!important" target="_blank" to="/about">About</Link>
                                    <Link fontWeight="900!important" target="_blank" to="/blogs">Blog</Link>
                                </VStack>
                            </GridItem> */}
                        </Grid>
                        {/* </Flex> */}
                        <Flex className='text_deco facts'
                            flexWrap="wrap"
                            alignItems="center"
                            justifyContent={{ base: 'start', sm: 'start', md: 'center', lg: 'center', xl: 'center', }}
                            width={{ base: '75%', sm: '75%', md: '100%', lg: '100%', xl: '100%', }}
                            mt={{ base: '8', sm: '8', md: '4', lg: '4', xl: '4', }}
                            color={"#808080"}
                            fontSize="sm"
                            fontWeight="normal"
                        >
                            <Text className='facts' p="5px">2022 ©  HelpNow. All Rights Reserved </Text>
                            <Text mx="5px">
                                <Link to="/terms" > |   Terms & Policies  </Link></Text>
                            <Link to="/privacy-policy" p="5px">  |  Privacy-Policies  </Link>
                        </Flex>
                    </Box>
                </Box>
            </Box >
            <Box display={{ base: 'inherit', sm: 'inherit', md: 'inherit', lg: 'none', xl: 'none' }} zIndex={999} width="100%" right={0} left={0} position="fixed" bottom="0">
                <div className='navigation'>
                    <ul>
                        <li className='list active'>
                            <a href={whatsapplink}>
                                <span className='icon'><AiOutlineWhatsApp /></span>
                                <span className='text'>Whatsapp</span>
                                <span></span>
                            </a>
                        </li>
                        <li className='list home'>
                            <a href='/'>
                                <span className='icon'><AiOutlineHome /></span>
                                <span className='text'>Home</span>
                                <span></span>
                            </a>
                        </li>
                        <li className='list'>
                            <a href={`Tel:${contactnumber}`}>
                                <span className='icon'><HiOutlinePhone /></span>
                                <span className='text'>Call</span>
                                <span></span>
                            </a>
                        </li>
                    </ul>
                    <div className='indicator'></div>
                </div>
            </Box>
        </>
    )
}

export default Footer