import { Box, Img, Text } from "@chakra-ui/react";
import React from "react";
const FeaturedContent = (props) => {
  return (
      <Box bg='#F7F7F7'
        minHeight='21em'
        ml={{ base: '0', sm: '0', md: '5px', lg: '5px', xl: '20px' }}
        maxW={{ base: '100%', sm: '100%', md: '100%', lg: '360px', xl: '360px' }} >
        <Img loading='lazy' width="100%" height="100%" objectFit="cover" alt='helpnow' src={props.featuredimg} />
        <Box p='15px 26px' lineHeight='2'>
          <Text fontWeight='800' color='#000'>{props.featuredheading}</Text>
          <Text onClick={() => { window.open(`${props.golink}`) }} color='red' fontSize='10px' fontWeight='600' cursor='pointer' fontStyle='italic' >Go to Article </Text>
        </Box>
      </Box>
  )
}
export default FeaturedContent