import React from 'react'
import { Link } from 'react-router-dom'

const Customlink = ({ tolink, text }) => {
    return (
        <Link to={tolink}
            _hover={{
                textDecoration: 'none'
            }}
            style={{ fontSize: '14px', fontWeight: '400', letterSpacing: '1.5%', color: '#454545' }} className='Manrope'>
            {text}
        </Link>
    )
}

export default Customlink