import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineRight } from 'react-icons/ai'

const Breadcrumbhelp = (props) => {
    return (
        <Box
            maxW={{ base: '24rem', sm: '24rem', md: '44rem', lg: '60rem', xl: '86rem' }}
            mx="auto"
display='none'
        >
            <Breadcrumb fontSize='10px' separator={<AiOutlineRight fontSize={8} color="gray.500" />} pb='10px'>
                <BreadcrumbItem>
                    <BreadcrumbLink
                        href='/'
                        fontSize='10px'
                        fontWeight='600'
                        textDecoration='none !important'>Home</BreadcrumbLink>
                </BreadcrumbItem>
                {/* <BreadcrumbItem>
                <BreadcrumbLink
                    href={props.middlelink}
                    fontSize='10px'
                    fontWeight='600'
                    textDecoration='none !important'>{props.middletital}</BreadcrumbLink>
            </BreadcrumbItem> */}
                <BreadcrumbItem>
                    <BreadcrumbLink
                        href='#'
                        fontSize='10px'
                        fontWeight='600'
                        color='red'
                        textDecoration='none !important'
                        cursor='auto !important'>{props.defaulttital}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
        </Box>
    )
}

export default Breadcrumbhelp