import { Img } from '@chakra-ui/image'
import { Box, ListItem, Text, UnorderedList, VStack, Center } from '@chakra-ui/layout'
import React from 'react'
import Group1224 from '../../assets/image/Group1224.webp'
import homegrp2 from '../../assets/image/homegrp2.svg'
import HomeWhatappIcon from '../Atoms/HomeWhatappIcon'
import CallBackForm from '../HeroForm'
import Thumbsup from '../../assets/icon/thumbsup.png'
import Group from '../../assets/icon/Group.png'
import Todolist from '../../assets/icon/todolist.png'
import Vector from '../../assets/icon/Vector.png'

const Hero = () => {
    return (
        <Box as='main' position='relative'>
            <Box
                maxW={{ base: '90vw', sm: '90vw', md: "85vw", lg: '85vw', xl: '85vw' }}
                mx="auto"
                position='relative'
                mb={{ base: '8', sm: '8', md: '8', lg: '8', xl: '44' }}
            >
                <Img
                    display={{ base: 'none', sm: 'none', md: 'block' }}
                    width="auto"
                    objectFit="cover"
                    height={{ base: '20rem', sm: '20rem', md: "auto" }}
                    mx="auto"
                    alt='helpnow'
                    src={Group1224} />
                <Img
                    display={{ base: 'block', sm: 'block', md: 'none' }}
                    width="auto"
                    // objectFit="cover"
                    height={{ base: '20rem', sm: '20rem', md: "auto" }}
                    mx="auto"
                    loading='lazy'
                    alt='helpnow'
                    src={homegrp2} />
                <HomeWhatappIcon />
                <VStack
                    // mt={{ base: '0', sm: '0', md: "-3rem", lg: '-3rem', xl: '-3rem' }}
                    alignItems="self-start" position={{ base: 'relative', sm: 'relative', md: 'relative', lg: 'relative', xl: 'absolute' }} top='0'>
                    <Text className='image-text' fontWeight={500} fontSize={{ base: '28', sm: '28', md: "45px", lg: '45px', xl: '45px' }} as="h1">Nearest ambulance to you, <br />
                        dispatched within <Text as="span" color="red">60 <br /> seconds</Text>  of your call.
                    </Text>
                    {/* <Text ffontSize={{ base: 'sm', sm: 'sm', md: "xl", lg: 'xl', xl: 'xl' }} mt={2} as="p">Get safest 24x7 medical response in your city</Text> */}
                    {/* <br /> */}
                    <UnorderedList marginTop={'2rem'} fontSize="1.3rem" className='banner' spacing={{ base: '4', sm: '4', md: "6", }}>
                        {/* <ListItem>Fast Response Time</ListItem>
                            <ListItem>24/7 Availability</ListItem>
                            <ListItem>Well-equipped Ambulances</ListItem>
                            <ListItem>Affordable Prices</ListItem>
                            <ListItem>Patient Comfort</ListItem> */}

                        <ListItem display="flex" alignItems="center" marginBottom="1rem" gap={{ base: '3', sm: '3', md: '6' }}>
                            <Center border='solid 1px rgba(255, 17, 17, 0.11)' bg='rgba(251, 25, 35, 0.08)' p={1} rounded='md'>
                                <Img
                                    loading='lazy' width='32px' alt='helpnow'
                                    src={Group} /></Center>
                            <Text>Rapid Emergency Response</Text>
                        </ListItem>
                        <ListItem display="flex" alignItems="center" marginBottom="1rem" gap={{ base: '3', sm: '3', md: '6' }}>
                            <Center border='solid 1px rgba(255, 17, 17, 0.11)' bg='rgba(251, 25, 35, 0.08)' p={1} rounded='md'>
                                <Img
                                    loading='lazy' width='32px' alt='helpnow'
                                    src={Thumbsup} />
                            </Center>
                            <Text>Quality Care</Text>
                        </ListItem>
                        <ListItem display="flex" alignItems="center" marginBottom="1rem" gap={{ base: '3', sm: '3', md: '6' }}>
                            <Center border='solid 1px rgba(255, 17, 17, 0.11)' bg='rgba(251, 25, 35, 0.08)' p={1} rounded='md'>
                                <Img
                                    loading='lazy' width='32px' alt='helpnow'
                                    src={Todolist} />
                            </Center>
                            <Text>Skilled Doctors and Paramedics</Text>
                        </ListItem>
                        <ListItem display="flex" alignItems="center" marginBottom="1rem" gap={{ base: '3', sm: '3', md: '6' }}>
                            <Center border='solid 1px rgba(255, 17, 17, 0.11)' bg='rgba(251, 25, 35, 0.08)' p={1} rounded='md'>
                                <Img
                                    loading='lazy' alt='helpnow'
                                    src={Vector} width='32px' />
                            </Center>
                            <Text>Stationed Nearby Ambulances</Text>
                        </ListItem>
                    </UnorderedList>
                </VStack>
                <CallBackForm />
            </Box>
        </Box>
    )
}

export default Hero