import React, { Suspense } from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './containers/Home';
// import Header from './components/header';
import Footer from './components/footer';
// import Terms from './containers/Terms';
// import Blog from './containers/Blog';
// import Blogdetails from './containers/Blog/Blogdetails';
// import Mumbai from './containers/Mumbai';
// import Delhi from './containers/Delhi';
// import Bangalore from './containers/Bangalore';
// import Organisation from './containers/Organisation';
import Press from './containers/PressRelease';
// import Contact from './containers/Contactus';
// import LocationTracking from './containers/LocationTracking';
// import Privacy from './containers/Privacy/Privacy';
import { Helmet } from 'react-helmet';
import Header2 from './components/header/Header2';
// import BLS from './components/Ambulanceservices/BLS';
// import ALS from './components/Ambulanceservices/ALS';
// import NICU from './components/Ambulanceservices/NICU';
// import HEARSE from './components/Ambulanceservices/HEARSE';
// import AAS from './components/Ambulanceservices/AAS';
// import TAS from './components/Ambulanceservices/TAS';
import ScrollToTop from './components/Atoms/ScrolltoTop';
import { contactnumberwithcountrycode, logourl } from './lib/Constants';

const Terms = React.lazy(() => import('./containers/Terms'));
const Blog = React.lazy(() => import('./containers/Blog'));
const Blogdetails = React.lazy(() => import('./containers/Blog/Blogdetails'));
const Mumbai = React.lazy(() => import('./containers/Mumbai'));
const Delhi = React.lazy(() => import('./containers/Delhi'));
const Bangalore = React.lazy(() => import('./containers/Bangalore'));
const Organisation = React.lazy(() => import('./containers/Organisation'));
// const Press = React.lazy(() => import('./containers/PressRelease'));
const Contact = React.lazy(() => import('./containers/Contactus'));
const Privacy = React.lazy(() => import('./containers/Privacy/Privacy'));
const BLS = React.lazy(() => import('./components/Ambulanceservices/BLS'));
const ALS = React.lazy(() => import('./components/Ambulanceservices/ALS'));
const NICU = React.lazy(() => import('./components/Ambulanceservices/NICU'));
const AAS = React.lazy(() => import('./components/Ambulanceservices/AAS'));
const TAS = React.lazy(() => import('./components/Ambulanceservices/TAS'));
const HEARSE = React.lazy(() => import('./components/Ambulanceservices/HEARSE'));

function App() {

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "HelpNow",
    "url": window.location.host,
    "logo": logourl,
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": contactnumberwithcountrycode,
      "contactType": "Customer Service"
    }
  }

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Helmet>
          <title>GetHelpNow - 24 Hours Best Ambulance Service  || Home  | HelpNow</title>
          <meta name="description" content="HelpNow offers an ambulance service in Pune, Delhi-NCR, Bengaluru and Mumbai, providing fast and quality medical response. It builds a network of private ambulances, leveraged ridesharing cabs into 'MedCabs' and made technological optimizations to create the safest ambulance service." />
          <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
          <link rel="canonical" href={window.location.href} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="GetHelpNow - 24 Hours Best Ambulance Service | HelpNow" />
          <meta property="og:description" content="HelpNow offers an ambulance service in Pune, Delhi-NCR, Bengaluru and Mumbai, providing fast and quality medical response. It builds a network of private ambulances, leveraged ridesharing cabs into 'MedCabs' and made technological optimizations to create the safest ambulance service." />
          <meta property="og:url" content={window.location.host} />
          <meta property="og:image" content={logourl} />
          <meta property="og:image:width" content="200" />
          <meta property="og:image:height" content="50" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={window.location.host} />
          <meta name="twitter:title" content="GetHelpNow - 24 Hours Best Ambulance Service | HelpNow" />
          <meta name="twitter:description" content="HelpNow offers an ambulance service in Pune, Delhi-NCR, Bengaluru and Mumbai, providing fast and quality medical response. It builds a network of private ambulances, leveraged ridesharing cabs into 'MedCabs' and made technological optimizations to create the safest ambulance service." />
          <meta name="twitter:image" content={logourl} />
          <script type="application/ld+json">
            {JSON.stringify(jsonLdData)}
          </script>
        </Helmet>
        <Suspense>
          <Header2 />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/services" element={<Services />} /> */}
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/:blogname" element={<Blogdetails />} />
            <Route path="/ambulance-service-in-mumbai" element={<Mumbai />} />
            <Route path="/ambulance-service-in-delhi" element={<Delhi />} />
            <Route path="/ambulance-service-in-bangalore" element={<Bangalore />} />
            <Route path="/organisation" element={<Organisation />} />
            <Route path="/press" element={<Press />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/location-tracking" element={<LocationTracking />} /> */}
            <Route path='/ambulance-service-in-mumbai/:area' element={<Mumbai />} />
            <Route path='/ambulance-service-in-delhi/:area' element={<Delhi />} />
            <Route path='/ambulance-service-in-bangalore/:area' element={<Bangalore />} />
            <Route path='/Services/BasicAmbulance' element={<BLS />} />
            <Route path='/Services/AdvanceAmbulance' element={<ALS />} />
            <Route path='/Services/NewbornsBabyICU' element={<NICU />} />
            <Route path='/Services/HearseAmbulanceServices' element={<HEARSE />} />
            <Route path='/Services/AirAmbulanceService' element={<AAS />} />
            <Route path='/Services/TrainAmbulanceService' element={<TAS />} />
          </Routes>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
