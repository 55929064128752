import { HStack, Menu, MenuButton, MenuList, MenuItem, Text, List, ListItem } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'

const NavMenu = ({ setShowheaderPopup }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuListHovered, setIsMenuListHovered] = useState(false);

  let isOnServices = false

  if (location.pathname.includes("Services")) {
    console.log("The string includes 'Services'.");
    isOnServices = true
  } else {
    console.log("The string does not include 'Services'.");
    isOnServices = false
  }

  console.log('pathname', location.pathname);

  const showMenu = () => {
    setIsHovered(true);
  };

  const hideMenu = () => {
    setIsHovered(false);
  };

  const keepMenuVisible = () => {
    setIsMenuListHovered(true);
    showMenu(); // To ensure menu remains visible when hovering over menu list
  };

  useEffect(() => {
    setIsHovered(false)
    setIsMenuListHovered(false)
  }, [location.pathname])





  return (
    <HStack
      justifyContent='center'
      // display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}
      className='open_header'
    >
      <NavLink style={{ height: '100px', display: 'flex', alignItems: 'center', visibility: 'hidden' }} activeClassName="active"
        onClick={setShowheaderPopup}
        to="/about">About </NavLink>
      <NavLink style={{ height: '100px', display: 'flex', alignItems: 'center' }} activeClassName="active" onClick={setShowheaderPopup} to="/press">Press Releases </NavLink>
      {/* <NavLink activeClassName="active" onClick={setShowheaderPopup} to="/organisation">For Organisations</NavLink> */}
      <div className="menu" onMouseLeave={hideMenu}>
        <div className="menu-button" onMouseEnter={showMenu}>
          Services
        </div>
        <List className="menu-list"
          style={{ display: isHovered || isMenuListHovered ? 'block' : 'none' }}
          onMouseEnter={keepMenuVisible} onMouseLeave={() => setIsMenuListHovered(false)}>
          <ListItem onClick={() => { navigate('/Services/BasicAmbulance') }} className="menu-item">BLS - Basic Ambulance </ListItem>
          <ListItem onClick={() => { navigate('/Services/AdvanceAmbulance') }} className="menu-item">ALS - Cardiac Ambulance </ListItem>
          <ListItem onClick={() => { navigate('/Services/NewbornsBabyICU') }} className="menu-item">NICU - Newborns Baby ICU  </ListItem>
          <ListItem onClick={() => { navigate('/Services/HearseAmbulanceServices') }} className="menu-item">Hearse Ambulance Service</ListItem>
          <ListItem onClick={() => { navigate('/Services/AirAmbulanceService') }} className="menu-item">Air Ambulance Service </ListItem>
          <ListItem onClick={() => { navigate('/Services/TrainAmbulanceService') }} className="menu-item">Train  Ambulance Service </ListItem>
        </List>
        <style jsx>{`
        .menu {
          position: relative;
        }

        .menu-button {
          cursor: pointer;
          color: ${isHovered || isMenuListHovered || isOnServices ? '#ff0000' : '#000'};
          border-bottom: ${isHovered || isMenuListHovered ? '3px solid #FB1923' : '3px solid transparent'};;
          padding-left: 2rem;
          padding-right: 2rem;
          font-size: 1rem;
          font-weight: 600;
          margin-inline-start: 0 !important;
          font-family: "Nunito", sans-serif !important;
          height: 100px;
          display: flex;
          align-items: center;
        }

          .menu-list {
          list - style: none;
          padding: 0;
          margin: 0;
          position: absolute;
          top : 100%;
          left: -33%;
          background-color: white;
          border: 1px solid #ccc;
          border-radius: 4px;
          transition: opacity 0.3s ease;
          opacity: ${isHovered || isMenuListHovered ? 1 : 0};
          display:block;
          width:233px;
        }
          .menu-button:hover,
          .menu-item:hover .menu-button
          {
            border-bottom: 3px solid #FB1923;
            color: #FB1923;
        }
          .menu-item {
          padding: 8px 16px;
          cursor: pointer;
          text-align: start;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

          .menu-item:hover {
            color:#ff0000
        }
      `}</style>
      </div>
      <NavLink style={{ height: '100px', display: 'flex', alignItems: 'center' }} activeClassName="active" onClick={setShowheaderPopup} to="/organisation">About us</NavLink>
      {/* <NavLink activeClassName="active" onClick={setShowheaderPopup} to="/services">Services</NavLink> */}
      <NavLink style={{ height: '100px', display: 'flex', alignItems: 'center' }} activeClassName="active"
        onClick={setShowheaderPopup}
        to="/contact">Contact</NavLink>
      <NavLink style={{ height: '100px', display: 'flex', alignItems: 'center' }} activeClassName="active"
        onClick={setShowheaderPopup}
        to="/blogs">Blogs</NavLink>
      {/* <NavLink activeClassName="active" >Location Tracking</NavLink> */}
    </HStack>
  )
}

export default NavMenu