import React from 'react'
import Release from './Release'
import { Helmet } from 'react-helmet'

const Press = () => {
    return (
        <>
            <Helmet>
                <title>Press Release | HelpNow</title>
                <meta name="description" content="HelpNow Press page" />
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
            </Helmet>
            <Release />
        </>
    )
}

export default Press