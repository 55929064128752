import { Box, Heading, Text, Button, AspectRatio, Image } from "@chakra-ui/react";
import React, { useRef } from "react";
// import helpnowAmbulance from '../../assets/image/helpnowAmbulance.webp'
import helpnowAmbulance1 from '../../assets/image/helpnowAmbulance1.webp'
import Breadcrumbhelp from "../bredcrumb";
import Slider from "react-slick";
import FeaturedContent from "../FeaturedContent";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import Ambu5678 from '../../assets/image/Ambu5678.webp'
import ICU_Ambulance from '../../assets/image/ICU_Ambulance.webp'
import ndtvbuilding12 from '../../assets/image/ndtvbuilding12.webp'
import punekarnews from '../../assets/image/punekarnews.webp'


const ReleaseSlider = () => {
    const ref = useRef()

    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        // initialSlide: 0,
        nextArrow: <GrFormNext color='red!important' />,
        prevArrow: <GrFormPrevious color='red!important' />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }
            }
        ]
    };

    return (
        <>
            <Box as='main'>
                <Breadcrumbhelp defaulttital="Press Releases" />
                <Box maxW='100%'
                    mx="auto"
                    position='relative'
                >
                    <AspectRatio ratio={2 / 1}>
                        <Image src={helpnowAmbulance1} alt='profile' objectFit='fill' />
                    </AspectRatio>
                    {/* <Img
                        objectFit='fill'
                        width='100%'
                        height='25em'
                        mx="auto"
                        src={helpnowAmbulance} /> */}
                    <Box display={{ base: 'none', sm: 'none', md: 'block' }} position='absolute' bottom={{ base: '1%', sm: '1%', md: '10%', lg: '10%', xl: '10%' }} right={{ base: '25%', sm: '20%', md: '0', lg: '0', xl: '0' }} width={{ base: '300px', sm: '300px', md: '400px', lg: '500px', xl: '500px' }} p={4}>
                        <Heading as={'h1'} className="image-text" fontWeight={700} fontSize={{ base: '18', sm: '18', md: '26', lg: '32', xl: '45' }} lineHeight={{ base: '18px', sm: '18px', md: '30px', lg: '51px', xl: '51px' }} color={'#fff'} >Press Releases covering HelpNow 24x7 Ambulance Service.</Heading>
                        <Text my={{ base: '1', sm: '1', md: '3' }} className="main-section" fontWeight={400} fontSize={{ base: '16', sm: '16', md: '24', lg: '24', xl: '29' }} lineHeight={{ base: '16px', sm: '16px', md: '30px', lg: '43px', xl: '43px' }} color={'#fff'} >Providing advanced cardiac care and intensive care support with a trained BHMS doctor onboard.</Text>
                        {/* <Button>Go to article</Button> */}
                        <Button
                            w={{ base: '60%', sm: '60%', md: '60%', lg: '60%', xl: '40%' }}
                            h={{ base: '30px', sm: '30px', md: '50px' }}
                            _hover={{ bg: 'red.600' }}
                            bg='#f12121'
                            color='#fff'
                            mt='10px'
                            fontSize='1rem'
                            fontWeight='400'
                            boxShadow='rgb(255 187 187) -5px 16px 49px -14px'
                            // onClick={() => { window.open(`https://www.indiatoday.in/magazine/cover-story/story/20210531-iitians-run-24x7-free-ambulance-service-for-the-needy-1805346-2021-05-21`) }}
                            onClick={() => { ref.current.scrollIntoView({ behavior: 'smooth' }) }}
                            borderRadius='6px'>Go to article</Button>
                    </Box>
                </Box>
            </Box>
            <Box as="main" ref={ref} overflow="hidden" py={{ base: '1rem', sm: '1rem', md: '4rem', lg: '4rem', xl: '4rem', }}>
                <Text
                    textAlign="center"
                    color="#FB1923"
                    textTransform="uppercase"
                    fontWeight={700}
                    fontSize={{ base: '2xl', sm: '2xl', md: '3xl' }}
                    letterSpacing="3px"
                    pb='2rem'
                    className="main-section"

                >Featured Articless</Text>
                <Box
                    maxW={{ base: '90vw', sm: '90vw', md: '78vw', lg: '78vw', xl: '78vw', }}
                    mx="auto"
                    my={{ base: 1, sm: 1, md: 'auto', lg: 'auto', xl: 'auto' }}
                >
                    <Slider {...settings} gap='10px'>
                        <FeaturedContent golink="https://www.indiatoday.in/magazine/cover-story/story/20210531-iitians-run-24x7-free-ambulance-service-for-the-needy-1805346-2021-05-21" featuredheading="India Today" featuredimg={helpnowAmbulance1} />
                        <FeaturedContent golink="https://timesofindia.indiatimes.com/city/mumbai/iit-bombay-students-alumni-team-up-for-24-hour-ambulance-service/articleshow/75959953.cms" featuredheading="Times Of India" featuredimg={Ambu5678} />
                        <FeaturedContent golink="https://www.thebetterindia.com/264256/ambulance-contact-emergency-number-helpnow-iit-aditya-makkar/" featuredheading=" The Better India" featuredimg={ICU_Ambulance} />
                        <FeaturedContent golink="https://www.ndtv.com/education/iit-bombay-students-alumni-run-free-emergency-ambulances-for-needy-2440163" featuredheading="NDTV" featuredimg={ndtvbuilding12} />
                        <FeaturedContent golink="https://www.expresshealthcare.in/covid19-updates/iit-bombay-alumni-provide-free-ambulance-for-the-needy/421262/" featuredheading="Punekarnews" featuredimg={punekarnews} />

                    </Slider>
                </Box>
            </Box>
        </>

    )
}
export default ReleaseSlider